<template>
  <!-- Main content -->
  <section class="content">
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加分类</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="channelClassifylist"
        border
        style="width: 100%;">
        <el-table-column fixed label="ID" prop="id" width="50"></el-table-column>
        <el-table-column fixed label="频道名称" prop="channel_name" width="120"></el-table-column>
        <el-table-column  label="频道称图片">
            <template slot-scope="scope">
                <el-image :src="scope.row.channel_img" :preview-src-list="[scope.row.channel_img]"></el-image>
            </template>
</el-table-column>
<el-table-column label="权重" prop="weight" width="50"></el-table-column>
<el-table-column label="备注" prop="remark"></el-table-column>
<el-table-column label="状态" prop="item_title" width="100">
    <template slot-scope="scope">
        {{scope.row.status | status}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getChannelClassifyDetail(item.row.id)">修改</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="频道名称">
            <el-input v-model="channel_name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="频道图片">
            <upLoadText @url="imgUrl" id="up" v-model="pic"></upLoadText>
        </el-form-item>
        <el-form-item label="频道权重">
            <el-input v-model="weight" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="remark" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="status">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="2">隐藏</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'channelClassify',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                dialog: false,
                channelClassifylist: [],

                id: '',
                channel_name: '', //频道名称
                pic: '', //频道图片
                weight: '', //权重
                remark: '', //备注
                status: '2', //状态
            }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '显示'
                        break;
                    case 2:
                        return '隐藏'
                        break;
                    default:
                        break;
                }
            }
        },
        mounted() {

            this.getChannelClassifyList()
        },
        methods: {
            // 获取列表
            getChannelClassifyList(page, size) {
                api.getChannelClassifyList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                }, res => {
                    this.total = Number(res.data.count)
                    this.channelClassifylist = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getChannelClassifyList()
            },
            currentChange(val) {
                this.page = val
                this.getChannelClassifyList()
            },
            //搜索
            seach(page) {
                this.getChannelClassifyList(page)
            },
            // 上传图片
            imgUrl(val) {
                this.pic = val
            },
            // 获取详情
            getChannelClassifyDetail(id) {
                this.id = id
                api.getChannelClassifyDetail({
                    id: id
                }, res => {
                    this.id = res.data.id
                    this.channel_name = res.data.channel_name
                    this.remark = res.data.remark
                    this.weight = res.data.weight
                    this.pic = res.data.channel_img
                    this.status = res.data.status
                    this.dialog = true
                })
            },
            // 添加选品
            add() {
                this.id = ''
                this.channel_name = ''
                this.remark = ''
                this.weight = ''
                this.pic = ''
                this.status = ''
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateChannelClassify({
                        id: this.id,
                        channel_name: this.channel_name,
                        remark: this.remark,
                        weight: this.weight,
                        channel_img: this.pic,
                        status: this.status,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getChannelClassifyList()
                    })
                    return
                }
                api.addChannelClassify({
                    channel_name: this.channel_name,
                    remark: this.remark,
                    weight: this.weight,
                    channel_img: this.pic,
                    status: this.status,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getChannelClassifyList()
                })
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    /* img {
        height: 40px;
    } */
</style>